import React from 'react'

import './footer.scss'

const Footer = () => (
  <div className='footer-container'>
    <hr />
    <div><small>Hype Template - All Rights Reserved</small></div>
    <div>
      <small>
        Website built by
        <a href='http://thehype.nz' target='_blank' rel='noopener noreferrer'>
          The Hype - Digital Agency
        </a>
      </small>
    </div>
  </div>)

export default Footer
