import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom'

import './menu.scss'

const Menu = ({ logo, items }) => {
  const [open, setOpen] = useState(false)

  const getLogo = () => (
    <a href='/'><img src={logo} alt='logo' className='menu-logo' /></a>
  )

  const toggleMenu = () => {
    // this fixex background scrolling when mobile menu is opened
    if (!open) {
      document.body.classList.add('noscroll')
    } else {
      document.body.classList.remove('noscroll')
    }
    setOpen(!open)
  }

  const getMenuItems = (isBurgerMenu) => (
    items.map((item) => (
      <a
        key={item.id}
        onClick={isBurgerMenu && toggleMenu}
        href={`${item.link}${item.scrollTo ? `${item.scrollTo}` : ''}`}
        className='menu-link'
      >
        {item.title}
      </a>
    )))

  if (!open) {
    return (
      <Headroom>
        <div className='menu-container'>
          {getLogo()}
          <div>{getMenuItems()}</div>
        </div>
        <div className='menu-burger'>
          {getLogo()}
          <button className='menu-burger-button' type='button' onClick={toggleMenu}>&#9776;</button>
        </div>
      </Headroom>)
  }

  return (
    <div className='menu-overlay'>
      <button href type='button' className='menu-closebtn' onClick={toggleMenu}>&times;</button>
      <div className='menu-overlay-content'>
        {getMenuItems(true)}
      </div>
    </div>)
}

Menu.propTypes = {
  logo: PropTypes.string,
  items: PropTypes.array,
}

export default Menu
