import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import './hero.scss'

const HeroSection = ({ image, description, title }) => {
  const heroStyle = {
    backgroundImage: `url('${image}')`,
    backgroundSize: 'cover',
  }

  return (
    <div className='hero-container' style={heroStyle}>
      <div className='hero-content'>
        <h1>{title}</h1>
        <Markdown options={{ html: true }}>
          {description}
        </Markdown>
      </div>
    </div>
  )
}

HeroSection.propTypes = {
  image: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
}

export default HeroSection
