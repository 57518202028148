import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import HeroSection from '../components/hero'
import ContactSection from '../components/contact'
import Layout from '../components/layout'
import { commonTags } from '../config/constants'

const PageTemplate  = ({ data }) => {
  const {
    ogTitle, ogKeywords, ogDescription, ogImage, favIco, location, items,
  } = data.contentfulPage
  return (
    <Layout location={location}>
      <Helmet
        title={ogTitle}
        meta={commonTags(ogTitle, ogDescription, ogKeywords, ogImage)}
      >
        <link rel='icon' type='image/png' href={favIco && favIco.file.url}  />
        <link href='https://fonts.googleapis.com/css?family=Roboto:400,900' rel='stylesheet' />
      </Helmet>
      <div style={{ minHeight: '75vh' }}>
        {
          items && items.map((section) => {
            switch (section.__typename) {
              case 'ContentfulHeroSection':
                return (
                  <HeroSection
                    key={section.name}
                    title={section.title}
                    description={section.description && section.description.description}
                    image={section.image.file.url}
                  />
                )
              case 'ContentfulContactSection':
                return (
                  <ContactSection
                    key={section.name}
                    title={section.title}
                    description={section.description && section.description.description}
                  />
                )
              default:
                return null
            }
          })
        }
      </div>
    </Layout>
  )
}

export const pageDataQuery = graphql`
  query ($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      ogTitle
      ogDescription
      ogKeywords
      ogImage {
        description
        file {
          url
          contentType
          details {
            size
            image {
              width
              height
            }
          }
        }
      }
      items {
        ... on ContentfulHeroSection {
          ... HeroSectionFragment
        }
        ... on ContentfulContactSection {
          ... ContactSectionFragment
        }
      }
      heroText
      favIco {
        file {
          url
        }
      }
    }
  }
`

export const contactSectionQuery = graphql`
  fragment ContactSectionFragment on ContentfulContactSection {
    __typename
    name
    title
    description {
      description
    }
  }
`

export const heroSectionQuery = graphql`
  fragment HeroSectionFragment on ContentfulHeroSection {
    __typename
    name
    title
    description {
      description
    }
    image {
      file {
        url
      }
    }
  }
`

PageTemplate.propTypes = {
  data: PropTypes.object,
}

export default PageTemplate
