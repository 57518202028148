import React, { useState } from 'react'
import ReactGA from 'react-ga'

import Button from '../button'
import './contact.scss'

import { CONTACT_FORM } from '../../config/analytics-events'

const emptyFields = {
  inputName: '',
  inputEmail: '',
  inputPhone: '',
  inputMessage: '',
}

const ContactForm = () => {
  const [fields, setField] = useState(emptyFields)
  const [contactMessage, setContactMessage] = useState(null)
  const [sending, setSending] = useState(false)

  const handleChange = (event) => {
    setField({ ...fields, [event.target.name]: event.target.value })
  }

  const formEncode = (obj) => {
    const str = []
    Object.keys(obj).forEach((key) => {
      str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`)
    })
    return str.join('&')
  }

  const resetForm = () => {
    setField(emptyFields)
  }

  const submitForm = (e) => {
    if (e.isDefaultPrevented()) {
      // handle the invalid form...
      return false
    }
    const {
      inputName, inputEmail, inputMessage, inputPhone,
    } = fields

    e.preventDefault()
    setSending(true)
    fetch('/php/contact-form.php', {
      method: 'POST',
      mode: 'same-origin',
      credentials: 'same-origin',
      headers: { 'Content-type': 'application/x-www-form-urlencoded' },
      body: formEncode({
        emailTitle: 'Message from website',
        inputName,
        inputPhone,
        inputEmail,
        inputMessage,
      }),
    })
      .then((res) => {
        setSending(false)
        if (res.ok) {
          ReactGA.event({
            category: CONTACT_FORM.category,
            action: CONTACT_FORM.successSubmitAction,
          })
          return res.json()
        }
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        const error = 'Error sending form, please try again later.'
        return { error }
      })
      .then((data) => {
        if (data.error) {
          setContactMessage({ success: false, message: data.error })
        } else {
          resetForm()
          setContactMessage({ success: true, message: data.messageEnglish })
        }
      })
      .catch((error) => {
        console.error(error)
        ReactGA.event({
          category: CONTACT_FORM.category,
          action: CONTACT_FORM.errorSubmitAction,
        })
        setSending(false)
      })
  }

  const {
    inputName, inputEmail, inputPhone, inputMessage,
  } = fields
  return (
    <div>
      <form
        id='contact_form'
        className='contact-form'
        name='contactform'
        data-toggle='validator'
        onSubmit={submitForm}
      >
        <div className='form-group'>
          <input
            value={inputName}
            onChange={handleChange}
            name='inputName'
            min='2'
            id='inputName'
            placeholder='Name'
            type='text'
            required
          />
        </div>

        <div className='form-group'>
          <input
            value={inputEmail}
            onChange={handleChange}
            name='inputEmail'
            id='inputEmail'
            placeholder='Email'
            type='email'
            required
          />
        </div>

        <div className='form-group'>
          <input
            value={inputPhone}
            onChange={handleChange}
            name='inputPhone'
            id='inputPhone'
            placeholder='Phone'
            type='text'
            required
          />
        </div>

        <div className='form-group'>
          <textarea
            value={inputMessage}
            onChange={handleChange}
            id='inputMessage'
            name='inputMessage'
            placeholder='Message'
            className='form-control'
            type='text'
            rows='6'
            required
          />
        </div>

        <div className='form-group'>
          <Button isButton type='submit' disabled={sending}>
            Send
          </Button>
        </div>
        <div className='contact-message-container'>
          {contactMessage && contactMessage.success
            && <p className='contact-message-success'>{contactMessage.message}</p>
          }
          {contactMessage && !contactMessage.success
            && <p className='contact-message-error'>{contactMessage.message}</p>
          }
        </div>
      </form>
    </div>)
}

export default ContactForm
