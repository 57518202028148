import React from 'react'
import PropTypes from 'prop-types'
import Markdown from 'react-remarkable'

import ContactForm from './form'
import './contact.scss'

const ContactSection = ({ title, description }) => (
  <div id='contactUsSection' className='contact-container section-container'>
    <h2>{title}</h2>
    <div className='section-description'>
      <Markdown options={{ html: true }}>
        {description}
      </Markdown>
    </div>
    <ContactForm />
  </div>
)

ContactSection.propTypes =  {
  title: PropTypes.string,
  description: PropTypes.string,
}

export default ContactSection
